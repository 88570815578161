import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import SubscribeForm from '../components/SubscribeForm';
import '../styles/index.scss'

const IndexPage = ({ data }) => {
  const fluid = (data.wordpressPage.featured_media) ? data.wordpressPage.featured_media.localFile.childImageSharp.fluid : null

  return (
    <Layout>
      <SEO
        title="Home"
      />

      <Helmet
        bodyAttributes={{
          class: 'home'
        }}
      />

      <div className="home_content">
        {fluid &&
          <div>
            <Img className="home_content_img" fluid={fluid} alt={data.wordpressPage.title} />
          </div>
        }

        <div className="home_content_text"
          dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
        />

        <SubscribeForm />

      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid (maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`